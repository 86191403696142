import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'filter',
    'clearButton',
    'expandButton',
    'filterCount',
    'spinner',
  ]

  static values = {
    params: Object,
    loading: Boolean,
  }

  connect() {
    this.createObserver()
    this.attachHandlers()
    this.connected = true
  }

  paramsValueChanged() {
    this.setInputValues()
    this.updateFilterCounts()

    if (this.connected) {
      this.fetchResults()
      this.toggleExpandButtons()
      this.toggleClearButtons()
    }
  }

  loadingValueChanged(loading) {
    if (this.connected && this.hasSpinnerTarget) {
      this.spinnerTarget.classList.toggle('hidden', !loading)
    }
  }

  onInputFilterInput(event) {
    this.toggleInputOptions(event.target)
    this.scrollTop()
  }

  onInputFilterChange(event) {
    const value = event.target.value
    if (!value || !event.params.minLength || value.length >= event.params.minLength) {
      this.setFilter(event.target.name, value || undefined)
      this.scrollTop()
    }
  }

  onInputOptionClick(event) {
    const name = event.target.closest('[data-name]').dataset.name
    const value = event.target.dataset.value

    if (!$(event.target).parent().hasClass('closed')) {
      this.setFilter(name, value)
      this.scrollTop()
      $(event.target).attr('disabled', null)
    }
  }

  onCheckboxFilterChange(event) {
    let array = this.paramsValue[event.target.name] || []
    if (event.target.checked) {
      this.setFilter(event.target.name, array.concat(event.target.value))
      this.scrollTop()
    } else {
      array = array.filter(i => i !== event.target.value)
      this.setFilter(event.target.name, array.length ? array : undefined)
    }
  }

  onFilterCleared(event) {
    event.preventDefault()
    this.scrollTop()

    if (event.params.name) {
      this.clearFilter(event.params.name)
    } else {
      this.clearFilters()
    }
  }

  setInputValues() {
    this.filterTargets.forEach(target => this.setInputValue(target))
  }

  setInputValue(target) {
    switch (target.type) {
      case 'text':
        target.value = this.paramsValue[target.name] || ''
        this.toggleInputOptions(target)
      break
      case 'radio':
        target.checked = this.paramsValue[target.name] === target.value
      break
      case 'checkbox':
        target.checked = (this.paramsValue[target.name] || []).indexOf(target.value) > -1
      break
    }
  }

  toggleInputOptions(target) {
    const value = target.value
    const options = $(target).next('[data-name]').find('[data-value]')

    if (value) {
      const regex = new RegExp(value, 'i')
      options.each(function() {
        const match = this.dataset.value.match(regex) || false
        const parent = $(this).parent()

        parent.toggleClass('match', !!match).toggleClass('closed', !match)
        let disabled = (parent.hasClass('closed')) ? 'disabled' : null;
        $(this).attr('disabled', disabled);
      })
    } else {
      options.attr('disabled', null).parent().removeClass(['match', 'closed']);
    }
  }

  toggleClearButtons() {
    this.clearButtonTargets.forEach(target => this.toggleClearButton(target))
  }

  toggleClearButton(target) {
    if (target.dataset.filtersNameParam) {
      target.toggleAttribute('disabled', !this.paramsValue[target.dataset.filtersNameParam])
    } else {
      target.toggleAttribute('disabled', !this.hasFilters())
    }
  }

  toggleExpandButtons(force) {
    this.expandButtonTargets.forEach(target => this.toggleExpandButton(target, force))
  }

  toggleExpandButton(target, force) {
    target.toggleAttribute('disabled', force === undefined ? this.hasFilters() : force)
  }

  updateFilterCounts() {
    if (this.hasFilterCountTarget) {
      const count = this.filterCount()

      this.filterCountTargets.forEach(target => {
        target.innerText = `${count} Filter${count === 1 ? '' : 's'}`
      })
    }
  }

  filterCount() {
    return Object.values({
      ...this.paramsValue,
      page: undefined,
      query: undefined,
    }).filter(Boolean).length
  }

  hasFilters() {
    return this.filterCount() > 0
  }

  setFilter(name, value) {
    this.paramsValue = { ...this.paramsValue, page: undefined, [name]: value }
  }

  clearFilters() {
    this.paramsValue = { query: this.paramsValue.query }
  }

  clearFilter(name) {
    this.setFilter(name, undefined)
  }

  setPage(page) {
    this.setFilter('page', page)
  }

  fetchResultsDebounced() {
    window.clearTimeout(this.debounceTimer)
    this.debounceTimer = window.setTimeout(() => this.fetchResults(), 100)
  }

  fetchResults() {
    if (this.loadingValue && this.abort) {
      this.abort()
    }

    const params = new URLSearchParams()

    Object.keys(this.paramsValue).forEach(key => {
      params.set(key, this.paramsValue[key])
    })

    if (params.get('page') == '1') {
      params.delete('page')
    }

    const url = [
      `${location.pathname}.turbo_stream`,
      params.toString(),
    ].filter(Boolean).join('?')

    this.loadingValue = true

    const options = {}

    if (typeof AbortController !== 'undefined') {
      const controller = new AbortController()
      options.signal = controller.signal
      this.abort = () => controller.abort()
    }

    history.replaceState(history.state, '', url.replace('.turbo_stream', ''))

    fetch(url, options).then(response => (
      response.text()
    )).then(html => {
      Turbo.renderStreamMessage(html)
    }).then(() => {
      this.loadingValue = false
      this.abort = null

      setTimeout(() => {
        this.createObserver()
      }, 500)
    }).catch(err => {
      // Nothing to do here
    })
  }

  createObserver() {
    const target = document.querySelector('[data-next-page]')

    if (!target) {
      return false
    }

    const callback = (...args) => this.handleIntersect(...args)
    const options = { threshold: [0, 1.0] }

    const observer = new IntersectionObserver(callback, options)
    observer.observe(target)
  }

  handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)
        if (entry.target.dataset.nextPage) {
          this.setFilter('page', entry.target.dataset.nextPage)
        }
      }
    })
  }

  attachHandlers() {
    $(this.element).find('.header--js').click(function () {
      $(this).toggleClass('active')

      $(this).attr('aria-expanded', ( $(this).attr('aria-expanded') == 'true' ? 'false' : 'true' ))

      $(this).siblings('.accordion--js').slideToggle({ duration: 300 })
      $('.search-filter--expand-js').attr('data-state', $('.header--js.active').length ? 'expanded' : 'collapsed')
      $(this).parent().find('input:text:visible:first').focus()
    })

    $(this.element).find('.search-filter--expand-js').click( function(e) {
      e.preventDefault()

      if ($(this).attr('data-state') === 'collapsed') {
        $(this).attr('data-state', 'expanded')
        $('.header--js').addClass('active').attr('aria-expanded', 'true')
        $('.accordion--js').stop().slideDown({ duration: 300 })
      } else {
        $(this).attr('data-state', 'collapsed')
         $('.header--js').removeClass('active').attr('aria-expanded', 'false')
        $('.accordion--js').stop().slideUp({ duration: 300 })
      }
    })
  }

  scrollTop() {
    if(window.innerWidth < 1024) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
