import { Controller } from '@hotwired/stimulus'
import 'devbridge-autocomplete/dist/jquery.autocomplete'

export default class extends Controller {
  static targets = [
    'input',
  ]

  connect() {
    if (!this.hasInputTarget) {
      return
    }
    $(this.inputTarget).autocomplete({
      serviceUrl: '/search/autocomplete.json',
      minChars: 2,
      onSelect: suggestion => {
        window.location = suggestion.data.url
      }
    })
  }

  submit(event) {
    event.preventDefault()
    if (this.inputTarget.value) {
      window.location = `/search?query=${this.inputTarget.value}`
    }
  }
}
