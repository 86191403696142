import Cookies from 'js-cookie'

$(document).ready(function() {
  const cookieBox = document.getElementById('js-cookie-box');
  const cookieButton = document.getElementById('js-cookie-button');

  if (!Cookies.get('cookie_policy')) {
    if(cookieBox) {
      cookieBox.classList.remove('cookies--hide');
    }

    if(cookieButton) {
      cookieButton.onclick = function() {
        Cookies.set('cookie_policy', true, { expires: 7 });
        if(cookieBox) {
          cookieBox.classList.add('cookies--hide');
        }
      }
    }
  }
});
