import "select2";
import "select2/dist/css/select2.css";
import ScrollMagic from "scrollmagic";
import { TweenMax, Linear } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
import AOS from "aos";
import "aos/dist/aos.css";

// Browser Class

function getBrowserName() {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator

  let userAgent = navigator.userAgent;

  if (userAgent.includes("Firefox")) {
    return "firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    return "samsung";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "opera";
  } else if (userAgent.includes("Trident")) {
    return "ie";
  } else if (userAgent.includes("Edge")) {
    return "edge-legacy";
  } else if (userAgent.includes("Edg")) {
    return "edge";
  } else if (userAgent.includes("Chrome")) {
    return "chrome";
  } else if (userAgent.includes("Safari")) {
    return "safari";
  } else {
    return "unknown";
  }
}

// Global CSS Variables

const root = document.documentElement;

const setCssVars = () => {
  let vh = window.innerHeight;
  root.style.setProperty("--viewport-height", `${vh}px`);
};

window.addEventListener("resize", setCssVars, true);

setCssVars();

$(document).ready(function () {
  // Cache frequent referenced DOM elements

  const $body = $("body");
  const $alert = $(".alert");
  const $header = $(".header");

  $body.addClass(`browser-${getBrowserName()}`);

  // Mobile hamburger search functionality
  const textInput = $(".js-input");
  const inputReset = $(".js-reset");
  const mobileNav = $(".header__navigation--mobile");

  // Hamburger Menu Function
  $(".header__hamburger").click(function () {
    $(this).toggleClass("animate");

    $(".header__navigation--mobile").slideToggle("slow").toggleClass("active");
    $(".header__navigation-items--mobile").toggleClass("active");
    $(".header__logo").toggleClass("active");
    $(".header__socials--mobile").toggleClass("active");
    $(".header > container").toggleClass("active");
    $header.toggleClass("active");

    if (!textInput.val() == "") {
      textInput.val("");
      inputReset.fadeOut();
      mobileNav.removeClass("search-active");
    }

    setTimeout(function () {
      $(".header__logo--in-menu").toggleClass("active");
    }, 300);
  });

  // If scrollbar reached the top disable white background on the header
  if ($(".upscroll_inactive").length === 0) {
    //$('.header').toggleClass('no-scroll'); // Now Applied on template

    $(window).scroll(function () {
      if (parseInt($(window).width()) > 991) {
        $header
          .add($body)
          .toggleClass("scroll", $(this).scrollTop() > $("header").height());
      } else {
        $header.add($body).removeClass("scroll");
      }
    });
  }

  // Search functionality
  $('a[href="#search"]').click(function (event) {
    event.preventDefault();
    $("#search-box").addClass("open");
    $("#search-box .js-input").trigger("focus");
    $("body").addClass("no-scroll");
  });

  // Search close functionality
  $('a[href="#close"]').click(function (event) {
    event.preventDefault();
    $("#search-box").removeClass("open");
    $("body").removeClass("no-scroll");
  });

  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      // escape key maps to keycode `27`
      $("#search-box").removeClass("open");
    }
  });

  textInput.on("input", function () {
    if (!$(this).val() == "") {
      inputReset.fadeIn();
    } else {
      inputReset.fadeOut();
    }
  });

  if (parseInt($(window).width()) < 1300) {
    textInput.on("input", function () {
      if (!$(this).val() == "") {
        mobileNav.addClass("search-active");
      } else {
        mobileNav.removeClass("search-active");
      }
    });
  }

  // Search input reset
  inputReset.click(function (e) {
    e.preventDefault();

    textInput.val("");
    mobileNav.removeClass("search-active");
    $(this).fadeOut();
  });

  // AOS initialisation

  AOS.init({
    duration: 800,
  });

  // FadeInUp on Scroll

  let controllerFadeUp = new ScrollMagic.Controller();

  $(".fadein-up").each(function (i) {
    let tween = new TweenMax.from($(this), 0.5, {
      autoAlpha: 0,
      y: "+=20",
      x: "0",
      ease: Linear.easeNone,
      delay: i * 0.02,
    });

    // build a scene
    let scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1.4,
      offset: 0,
      reverse: false,
    })
      .setTween(tween)
      .addTo(controllerFadeUp);
  });

  // FadeInUp on Scroll

  let controllerFadeUp2 = new ScrollMagic.Controller();

  $(".fadein-up2").each(function (i) {
    let tween = new TweenMax.from($(this), 1.2, {
      autoAlpha: 0,
      y: "+=20",
      x: "0",
      ease: Linear.easeNone,
      delay: i * 0.06,
    });

    // build a scene
    let scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1.4,
      offset: 0,
      reverse: false,
    })
      .setTween(tween)
      .addTo(controllerFadeUp2);
  });

  let controllerFade = new ScrollMagic.Controller();

  $(".fade-in").each(function (i) {
    // let tween = new TweenMax.from($(this), 0.8, {autoAlpha: 0, y: '0', x: '0', ease:Linear.easeNone, delay: i * 0.06});
    let tween = new TweenMax.from($(this), 0.8, {
      opacity: 0,
      y: "0",
      x: "0",
      ease: Linear.easeNone,
      delay: i * 0.06,
    });

    // build a scene
    let scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      offset: 0,
      reverse: false,
    })
      .setTween(tween)
      .addTo(controllerFade);
  });

  let controllerFade2 = new ScrollMagic.Controller();

  $(".fade-in2").each(function (i) {
    //let tween = new TweenMax.from($(this), 0.8, {autoAlpha: 0, y: '0', x: '0', ease:Linear.easeNone, delay: i * 0.06});
    let tween = new TweenMax.from($(this), 0.8, {
      opacity: 0,
      y: "0",
      x: "0",
      ease: Linear.easeNone,
      delay: i * 0.06,
    });

    // build a scene
    let scene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      offset: 0,
      reverse: false,
    })
      .setTween(tween)
      .addTo(controllerFade2);
  });

  // Initialise Select 2

  $(".js-select3").select2({
    containerCssClass: "filter-container",
    dropdownCssClass: "filter-dropdown",
  });

  $(".js-select2").select2({
    closeOnSelect: false,
    placeholder: "Filters",
    allowHtml: true,
    allowClear: false,
    tags: true,
  });

  $(".js-select2").on("change", function (e) {
    $(this).find(".select2-search__field").css("width", "100%");
    $(this)
      .next()
      .find(".select2-search__field")
      .attr("placeholder", "Filters");
  });

  // Filter modal popup

  let filterPopupBtn = $(".js-filter-modal-btn");
  let filterPopupClose = $(".js-filter-modal-close");
  let filterPopup = $(".js-filter-modal");
  let filterApplyBtn = $(".js-filter-apply-btn");

  filterPopupBtn.click(function (e) {
    e.preventDefault();

    if (!filterPopup.hasClass("active")) {
      filterPopup.fadeIn().addClass("active");
      $body.css({ overflow: "hidden" });
      $(this).fadeOut();
    } else {
      filterPopup.fadeOut().removeClass("active");
      $body.css({ overflow: "auto" });
    }
  });

  filterPopupClose.click(function (e) {
    e.preventDefault();

    if (filterPopup.hasClass("active")) {
      filterPopup.fadeOut().removeClass("active");
      filterPopupBtn.fadeIn();
      $body.css({ overflow: "auto" });
    }
  });

  filterApplyBtn.click(function () {
    if (filterPopup.hasClass("active")) {
      filterPopup.fadeOut().removeClass("active");
      filterPopupBtn.fadeIn();
      $body.css({ overflow: "auto" });
    }
  });

  // Global stripe function

  if ($(".js-common-stripe-left").length) {
    let stripeLeft = $(".js-common-stripe-left");
    let stripeRight = $(".js-common-stripe-right");
    let mainContainer = $(".js-main-container");
    let stripeContent = $(".js-stripe-content");

    function stripeArrange() {
      let stripeContentOffset = stripeContent.get(0).offsetTop;
      let sideSpace = ($(window).width() - mainContainer.width()) / 2;
      let stripeContentHeight = $(".js-stripe-content").height();

      if ($(window).width() <= 1023) {
        stripeLeft.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      }
      if ($(window).width() >= 1024) {
        stripeLeft.css({
          left: -sideSpace,
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
        stripeRight.css({
          right: -sideSpace,
          height: stripeContentHeight,
          top: stripeContentOffset,
        });
      }
    }

    stripeArrange();

    $(window).resize(function () {
      stripeArrange();
    });
  }

  // Global back to top

  $(window).scroll(function () {
    if ($body.hasClass("home")) {
      const hero = $(".hero").height();

      if ($(this).scrollTop() > hero * 3.25) {
        $(".js-back-to-top").fadeIn();
        $(".js-hero-menu").fadeOut();
      } else {
        $(".js-back-to-top").fadeOut();
        $(".js-hero-menu").fadeIn();
      }
    } else {
      if ($(this).not().scrollTop() > 600) {
        $(".js-back-to-top").fadeIn();
        $(".js-hero-menu").fadeOut();
      } else {
        $(".js-back-to-top").fadeOut();
        $(".js-hero-menu").fadeIn();
      }
    }

    if (
      $(window).scrollTop() + $(window).height() <
      $(document).height() - $(".footer").height()
    ) {
      $(".js-back-to-top").css("position", "fixed");
      $(".js-back-to-top").css("bottom", "0");
    }

    if (
      $(window).scrollTop() + $(window).height() >
      $(document).height() - $(".footer").height()
    ) {
      $(".js-back-to-top").css("position", "absolute");
      $(".js-back-to-top").css({ bottom: "0", "margin-left": "auto" });
    }
  });

  $(".js-back-to-top").click(function (e) {
    e.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 1000);
  });

  /**
   * Header active state
   */

  let url = window.location.href;
  url = url.split("/");

  $(".header__navigation li a").each(function () {
    let link = this.href.split("/");

    if (link[3] && url[3] && link[3] === url[3]) {
      $(this).addClass("nav-active");
    }
  });

  /**
   * Show alert banner and add necessary classes to other components.
   */

  const KEY = "addisonAlert";

  function showAlert() {
    if (!$alert.length) {
      return false;
    }

    const alertHeight = $alert.outerHeight();

    root.style.setProperty("--alert-height", `${alertHeight}px`);

    $alert.addClass("shown");

    $body.addClass("has-alert");

    if ($header.length > 0 && $alert.length > 0) {
      const pos = $alert.offset().top + alertHeight;

      $header.addClass("has-alert");
      $header.css({ top: pos + "px" });
    }

    $(document).trigger("alert-changed");
  }

  /**
   * Hide alert banner and remove related classes from other components.
   */

  function hideAlert() {
    $alert.removeClass("shown");
    $body.removeClass("has-alert");

    if ($header.length > 0) {
      // const pos = $('.place-social-share, .content-detail-share').offset().top - alertHeight;
      $header.removeClass("has-alert");
      $header.removeClass("alert-visible");
      // $('.place-social-share, .content-detail-share').css({ 'top': pos + 'px' });
    }

    $(document).trigger("alert-changed");
  }

  /**
   * Set navbar position so the alert banner is visible when scrolled to top of page.
   */

  function leaveRoomForAlert() {
    if ($alert.hasClass("shown")) {
      const dtop = $(document).scrollTop();
      const alertHeight = $alert.outerHeight();

      root.style.setProperty("--alert-height", `${alertHeight}px`);

      if (dtop > alertHeight) {
        $header.css({ top: 0 });
        $header.removeClass("alert-visible");
      } else {
        $header.css({ top: alertHeight - dtop + "px" });
        $header.addClass("alert-visible");
      }
    } else {
      $header.css({ top: 0 });
    }
  }

  // set initial navigation state according to alert

  leaveRoomForAlert();

  // track page scroll

  $(window).scroll(function () {
    // set upscroll state on navbar
    // setNavbarUpscrollStyling();
    //if ($('.home').length === 0) {
    // make room for alert banner when at top of screen
    leaveRoomForAlert();
    //}
  });

  // adjust nav when alert is shown or hidden

  $(document).on("alert-changed", function () {
    leaveRoomForAlert();
  });

  /**
   * Display or hide alert banner according to window size and session state.
   */

  function displayAlertAccordingToState() {
    // visible on mobile if not hidden by user
    if (sessionStorage.getItem(KEY)) {
      hideAlert();
    } else {
      showAlert();
    }
  }

  // hide alert if previously hidden (mobile only)

  displayAlertAccordingToState();

  // adjust alert when changing screen size

  $(window).resize(function () {
    displayAlertAccordingToState();
  });

  // hide alert on mobile when X is tapped

  $(".alert .alert__close").click(function () {
    hideAlert();

    // prevent it reappearing during this session
    sessionStorage.setItem(KEY, true);
  });

  $(document).on("click", ".js-link", function (e) {
    if (!e.isDefaultPrevented()) {
      e.preventDefault();
      let linkURL = $(this).data("url");
      location.href = linkURL;
    }
  });

  $(document).on("click", ".js-phone", function (e) {
    e.stopPropagation();
  });

  $(document).on("click", ".js-email-link", function (e) {
    e.preventDefault();
    let linkEmailURL = $(this).data("email");

    // if (!$(".js-modal-submit").is("[disabled=disabled]")) {
    //   $(".js-modal-submit").attr("disabled", true);
    // }

    // Check element has scrollbar function
    // $.fn.hasScrollBar = function () {
    //   return this[0].clientHeight < this[0].scrollHeight;
    // };

    // let content = $(".js-modal-content");

    // Check element has scrollbar
    // if (content.hasScrollBar()) {
    //   // Detect bottom of the element on scroll
    //   $(".js-modal-content").bind("scroll", function () {
    //     if (
    //       $(this).scrollTop() + $(this).innerHeight() >=
    //       $(this)[0].scrollHeight
    //     ) {
    //       $(".js-modal-submit").removeAttr("disabled");
    //     }
    //   });
    // } else {
    //   $(".js-modal-submit").removeAttr("disabled");
    // }

    // Open the email window once the submit
    $(".js-modal-submit").click(function () {
      window.location.href = "mailto:" + $(".hidden-value").text();
      modalPopupClose();
    });

    $body.css("overflow", "hidden");
    $(".js-modal-disclaimer").addClass("open");
    $(".js-modal-content").scrollTop(0);
    $(".hidden-value").html(linkEmailURL);
  });

  // Close disclaimer modal

  $(".js-modal-disclaimer .close").click(function () {
    modalPopupClose();
  });

  function modalPopupClose() {
    $body.css("overflow", "");
    $(".js-modal-disclaimer").removeClass("open");
  }
});
